@layer components {

    /* Menu Hamburger Button */
    button.menu {
        & span {
            display: block;
            width: 60%;
            height: 4px;
            border-radius: 2px;
            position: relative;
            @apply bg-primary;
            
            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                @apply bg-primary;
                left: 0;
                top: 0;
                border-radius: 2px;
                transition: 250ms ease;
            }

            &::before {
                transform: translateY(-8px)
            }
            
            &::after {
                transform: translateY(8px)
            }
        }
    }

    .nav-open {
        & nav.main-nav {
            display: block;
        }
        & button.menu {
            & span {
                background-color: transparent;

                &::before {
                    opacity: 1;
                    transform: translateY(0px) rotate(45deg);
                }
                &::after {
                    opacity: 1;
                    transform: translateY(0px) rotate(-45deg);
                    bottom: 0;
                }
            }
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select {
        @apply border border-gray-300 rounded shadow-sm block w-full p-2 pt-3 text-base;

        &:focus {
            @apply border-primary
        }

        &.error {
            @apply border-red-700
        }
    }

    label {
       @apply relative top-2.5 left-1.5 bg-white px-1 text-sm text-gray-700 z-10
    }
    
    [type="radio"] {
        & + label {
            @apply static top-0 left-0 font-normal text-base z-auto
        }

        &:checked + label {
            @apply bg-green-100
        }
    }

    .form-field-error {
        @apply text-sm font-bold px-2 text-red-700
    }

    .no-js {
        & .form-footer {
            display: none;
        }
        & .form-step:last-child .form-footer {
            display: flex;

            & .form-previous {
                display: none;
            }
        }
    }

    a.button, button.button {
        @apply bg-highlight text-black px-6 py-2 rounded font-bold no-underline inline-block disabled:bg-gray-200 disabled:text-gray-600;
        
        /* purgecss start ignore */
        &:hover {
            @apply transition shadow-md bg-yellow-400 disabled:bg-gray-300 disabled:text-gray-600
        }

        &:active {
            @apply ring ring-offset-2 ring-yellow-400
        }
        
        &:focus {
            @apply ring ring-offset-2 ring-yellow-300 outline-none shadow-md
        }
        /* purgecss end ignore */

        &.primary {
            @apply bg-primary text-white
        }

        &.full {
            @apply block w-full
        }

        &.large {
            @apply py-4
        }
    }


    details {
        & summary {
            position: relative;
            padding-left: 3rem;
            list-style: none;

            /* purgecss ignore */
            &::-webkit-details-marker {
                display: none;
            }
    
            &::before {
                content: "+";
                width: 3rem;
                margin: -1rem 1rem -1rem 0;
                padding: 1rem 0;
                position: absolute;
                left: 0;
                text-align: center;
                font-size: 1.5rem;
                box-sizing: border-box;
                height: 100%;
            }
        }
    
        &[open] {
            & summary {    
                &::before {
                    content: "–";
                }
            }
        }
    }

    #alert-banner {        
        & .banner {
            @apply transform transition
        }
        &.offscreen {
            @apply invisible h-0 overflow-hidden;

            & .banner {
                @apply invisible translate-y-full
            }
        }
    }

    .degree-area {
        
        @media (min-width: 1020px) {

        ul {
            display: none;
        }

        &:hover {
            & > ul {
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                width: 350px;
                min-height: 100%;
            }
        }
        
        }
    }

    .action {
        text-align: center;
    }

    button.default.form_button_submit {
        background: #eaab00;
        padding: 0.5rem 1.5rem;
        border-radius: 0.25rem;
        display: inline-block;
        font-weight: bold;
    }

    input {
        --tw-border-opacity: 1;
        border-color: rgba(209,213,219,var(--tw-border-opacity));
        border-radius: 0.25rem;
        border-width: 1px;
        width: 100%;
        padding: 0.75rem 0.5rem 0.5rem;
        box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
    }

    input:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 transparent);
    border-color: #2563eb;
    }

    
    .embed {
        padding-top: 56.25%;
        position: relative;
        background-position: center;
        background-size: cover;
        margin-bottom: 1.5rem;
    
        & a.play {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-indent: -9999px;
            /* background: url('../images/icon-youtube.svg') center no-repeat; */
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15%;
            opacity: 0.75;
            transition: opacity 150ms ease;
    
            &:hover {
                opacity: 1;
            }
        }
    
        & iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
    
            & + a.play {
                display: none;
            }
        }
    }
}